var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "container", staticClass: "container" }, [
    _c(
      "div",
      {
        style: {
          display: "flex",
          flexDirection: "column",
          height: "calc(100vh - 180px)"
        }
      },
      [
        _vm.showNoProfile
          ? _c(
              "div",
              [
                _c("page-header", {
                  attrs: { "title-size": "3rem", title: "프로필 등록 가이드" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "subtitle",
                        fn: function() {
                          return [
                            _vm._v(
                              " 헤이비글은 상세하고 전문적인 프로필을 통해 빠르게 공연 및 행사 매칭을 할 수 있는 "
                            ),
                            _c("strong", [_vm._v("플랫폼")]),
                            _vm._v("입니다."),
                            _c("br"),
                            _vm._v(" 전문성이 매우 중요한 만큼 자체 "),
                            _c("strong", [
                              _vm._v("심사를 통해 엄선된 분들만 활동이 가능")
                            ]),
                            _vm._v("합니다. ")
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    2570847457
                  )
                }),
                _c(
                  "div",
                  { staticClass: "guideLetter" },
                  [
                    _c("icon", {
                      attrs: {
                        src: "img/my-profile/megaphone.png",
                        width: "1.8rem",
                        height: "1.8rem",
                        cover: false
                      }
                    }),
                    _vm._v(
                      " 최대한 자세하고 전문성이 돋보이도록 프로필을 작성해주세요. "
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "profile-sec-tit" }, [
                  _vm._v("1. 심사일정")
                ]),
                _vm._m(0),
                _vm._m(1),
                _vm._m(2)
              ],
              1
            )
          : _c("div", [
              _c("div", { staticClass: "current-profile-area" }, [
                _c("div", { staticClass: "current-profile" }, [
                  _c("div", {
                    staticClass: "artist-img",
                    style: {
                      backgroundColor: "lightgrey",
                      // AItem.vue - backgroundImage: `url(${$lib.cdnUrl(row.teamImgUrl || row.imgUrl1)})`
                      backgroundImage: _vm.mainProfile
                        ? "url(" +
                          _vm.$lib.cdnUrl(
                            _vm.mainProfile.teamImgUrl ||
                              _vm.mainProfile.imgUrl1
                          ) +
                          ")"
                        : ""
                    }
                  }),
                  _c("div", { staticClass: "artist-info" }, [
                    _c(
                      "div",
                      {
                        staticClass: "profile-top",
                        on: {
                          click: function() {
                            return _vm.openBottomSheet()
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "artist-name" }, [
                          _vm._v(
                            _vm._s(
                              _vm.mainProfile && _vm.mainProfile.teamName
                                ? _vm.mainProfile.teamName
                                : ""
                            )
                          )
                        ]),
                        _vm.aprvProfiles.length > 0
                          ? _c(
                              "button",
                              {
                                staticClass: "change-profile-btn",
                                attrs: { type: "button" }
                              },
                              [
                                _c("icon", {
                                  attrs: {
                                    src: "img/common/arrow-down.png",
                                    width: "2.4rem",
                                    height: "2.4rem",
                                    cover: false
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.hasNewAlarm
                          ? _c("div", { staticClass: "new" })
                          : _vm._e()
                      ]
                    ),
                    _c("div", { staticClass: "artist-category" }, [
                      _vm._v(
                        _vm._s(
                          _vm.mainProfile && _vm.mainProfile.genre
                            ? _vm.mainProfile.genre
                            : ""
                        )
                      )
                    ])
                  ])
                ])
              ]),
              _vm.aprvProfiles && _vm.aprvProfiles.length > 0
                ? _c("div", { staticClass: "my-profile-list" }, [
                    _c("div", { staticClass: "profile-sec-tit" }, [
                      _vm._v("등록완료 "),
                      _c("span", { staticClass: "profile-num" }, [
                        _vm._v(_vm._s(_vm.aprvProfiles.length))
                      ]),
                      _vm._v("건")
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "artist-list-wrap",
                        class: { active: _vm.expandEnd == true }
                      },
                      [
                        _vm.aprvProfiles
                          ? _c("a-list", {
                              attrs: {
                                data: _vm.aprvProfiles,
                                noDivisionLine: "",
                                profilePage: { profile: true, state: "done" }
                              },
                              on: { "delete-profile": _vm.deleteProfile }
                            })
                          : _vm._e(),
                        _c("div", { staticClass: "btn-expand-wrap" }, [
                          _vm.aprvProfiles.length > 5
                            ? _c("button", {
                                staticClass: "btn-expand-list",
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.expandListEnd()
                                  }
                                }
                              })
                            : _vm._e()
                        ])
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm.notAprvProfiles && _vm.notAprvProfiles.length > 0
                ? _c("div", { staticClass: "my-profile-list" }, [
                    _c("div", { staticClass: "profile-sec-tit" }, [
                      _vm._v("등록대기 "),
                      _c("span", { staticClass: "profile-num" }, [
                        _vm._v(_vm._s(_vm.notAprvProfiles.length))
                      ]),
                      _vm._v("건")
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "artist-list-wrap",
                        class: { active: _vm.expandIng == true }
                      },
                      [
                        _vm.notAprvProfiles.filter(function(v) {
                          return v.artistProfileStep != 2
                        })
                          ? _c("a-list", {
                              attrs: {
                                data: _vm.notAprvProfiles.filter(function(v) {
                                  return v.artistProfileStep != 2
                                }),
                                noDivisionLine: "",
                                profilePage: { profile: true, state: "temp" }
                              },
                              on: { "delete-profile": _vm.deleteProfile }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.notAprvProfiles.filter(function(v) {
                          return v.artistProfileStep == 2
                        })
                          ? _c("a-list", {
                              attrs: {
                                data: _vm.notAprvProfiles.filter(function(v) {
                                  return v.artistProfileStep == 2
                                }),
                                noDivisionLine: "",
                                profilePage: { profile: true, state: "wait" }
                              },
                              on: { "delete-profile": _vm.deleteProfile }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "btn-expand-wrap" }, [
                          _vm.notAprvProfiles.length > 5
                            ? _c("button", {
                                staticClass: "btn-expand-list",
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.expandListIng()
                                  }
                                }
                              })
                            : _vm._e()
                        ])
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ]),
        _c(
          "footer-box",
          {
            style: {
              position: "sticky",
              bottom: 0,
              width: "calc(100% + 6rem",
              margin: "auto -3rem 0",
              zIndex: "initial"
            },
            attrs: {
              submitText: "프로필 추가",
              "submit-cb": function() {
                return _vm.nextStep(true)
              }
            }
          },
          [
            _c("icon", {
              style: {
                display: "inline-block",
                marginBottom: "-8px"
              },
              attrs: {
                slot: "submitLeftSlot",
                src: "img/common/plus-white.png",
                width: "3.2rem",
                height: "3.2rem",
                cover: false
              },
              slot: "submitLeftSlot"
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "profile-registration-step-wrap" }, [
      _c("ol", { staticClass: "profile-registration-step" }, [
        _c("li", [
          _c("img", {
            attrs: {
              src: "img/my-profile/step01@2x.png",
              alt: "프로필 등록 가이드 step1 아이콘 - 시계"
            }
          }),
          _c("div", [
            _c("div", { staticClass: "step-tit" }, [_vm._v("step 1")]),
            _c("div", { staticClass: "step-txt" }, [
              _vm._v(
                "‘입점 심사’ 요청 후 최대 24시간 내 진행 (공휴일 제외, 평일에만 진행)"
              )
            ])
          ])
        ]),
        _c("li", [
          _c("img", {
            attrs: {
              src: "img/my-profile/step02@2x.png",
              alt: "프로필 등록 가이드 step2 아이콘 - 메세지"
            }
          }),
          _c("div", [
            _c("div", { staticClass: "step-tit" }, [_vm._v("step 2")]),
            _c("div", { staticClass: "step-txt" }, [
              _vm._v("확인 후 개별적으로 결과 메시지 발송")
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "notice-area" }, [
        _c("div", { staticClass: "notice-tit" }, [_vm._v("※ 심사 거부 대상")]),
        _c("ul", { staticClass: "notice-list" }, [
          _c("li", [_vm._v("등록 가이드에 맞지 않은 프로필")]),
          _c("li", [_vm._v("무성의한 프로필")]),
          _c("li", [_vm._v("공연, 행사 무경험자")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "profile-sec-tit" }, [
      _vm._v("섭외율 높이는 "),
      _c("span", { staticClass: "line-badge" }, [_vm._v("Tip")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "tip-list" }, [
      _c("li", [
        _vm._v(
          "첫번째 사진은 첫인상과 같아요. 가장 자신있는 사진을 등록하세요."
        )
      ]),
      _c("li", [
        _vm._v(
          "영상은 다다익선! 행사, 공연 영상이 많은 분들이 섭외율이 높아요. (실력 확인이 되니깐요!)"
        )
      ]),
      _c("li", [_vm._v("활동경력을 상세하게 적으면 더 어필이 되겠죠?")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }