<template>
  <div class="container" ref="container">
    <div :style="{display: 'flex',flexDirection: 'column',height: 'calc(100vh - 180px)'}">
      <div v-if="showNoProfile"> <!-- CASE : profile 없음 -->
        <page-header
          title-size="3rem"
          :title="'프로필 등록 가이드'"
        >
          <template #subtitle>
            헤이비글은 상세하고 전문적인 프로필을 통해 빠르게 공연 및 행사 매칭을 할 수 있는 <strong>플랫폼</strong>입니다.<br />
            전문성이 매우 중요한 만큼 자체 <strong>심사를 통해 엄선된 분들만 활동이 가능</strong>합니다.
          </template>
        </page-header>
        <div class="guideLetter">
          <icon src="img/my-profile/megaphone.png" width="1.8rem" height="1.8rem" :cover="false" />
          최대한 자세하고 전문성이 돋보이도록 프로필을 작성해주세요.
        </div>
        <div class="profile-sec-tit">1. 심사일정</div>
        <div class="profile-registration-step-wrap">
          <ol class="profile-registration-step">
            <li>
              <img src="img/my-profile/step01@2x.png" alt="프로필 등록 가이드 step1 아이콘 - 시계">
              <div>
                <div class="step-tit">step 1</div>
                <div class="step-txt">‘입점 심사’ 요청 후 최대 24시간 내 진행 (공휴일 제외, 평일에만 진행)</div>
              </div>
            </li>
            <li>
              <img src="img/my-profile/step02@2x.png" alt="프로필 등록 가이드 step2 아이콘 - 메세지">
              <div>
                <div class="step-tit">step 2</div>
                <div class="step-txt">확인 후 개별적으로 결과 메시지 발송</div>
              </div>
            </li>
          </ol>
          <div class="notice-area">
            <div class="notice-tit">※ 심사 거부 대상</div>
            <ul class="notice-list">
              <li>등록 가이드에 맞지 않은 프로필</li>
              <li>무성의한 프로필</li>
              <li>공연, 행사 무경험자</li>
            </ul>
          </div>
        </div>

        <div class="profile-sec-tit">섭외율 높이는 <span class="line-badge">Tip</span></div>
        <ul class="tip-list">
          <li>첫번째 사진은 첫인상과 같아요. 가장 자신있는 사진을 등록하세요.</li>
          <li>영상은 다다익선! 행사, 공연 영상이 많은 분들이 섭외율이 높아요. (실력 확인이 되니깐요!)</li>
          <li>활동경력을 상세하게 적으면 더 어필이 되겠죠?</li>
        </ul>
      </div>
      <!-- CASE : profile 있음 -->
      <div v-else>
        <div class="current-profile-area">
          <div class="current-profile">
            <div 
              class="artist-img"
              :style="{
                backgroundColor: 'lightgrey',
                // AItem.vue - backgroundImage: `url(${$lib.cdnUrl(row.teamImgUrl || row.imgUrl1)})`
                backgroundImage: mainProfile ? `url(${$lib.cdnUrl(mainProfile.teamImgUrl || mainProfile.imgUrl1 )})` : ''
              }"
            >
            </div>
            <div class="artist-info">
              <div class="profile-top"  @click="() => openBottomSheet()">
                <div class="artist-name">{{mainProfile && mainProfile.teamName ? mainProfile.teamName : ''}}</div>
                <button
                  v-if="aprvProfiles.length > 0"
                  type="button"
                  class="change-profile-btn"
                >
                  <icon src="img/common/arrow-down.png" width="2.4rem" height="2.4rem" :cover="false"/>
                </button>
                <div v-if="hasNewAlarm" class="new"></div>
              </div>
              <div class="artist-category">{{mainProfile && mainProfile.genre ? mainProfile.genre : ''}}</div>
            </div>
          </div>
        </div>
        <div class="my-profile-list" v-if="aprvProfiles && aprvProfiles.length > 0">
          <div class="profile-sec-tit">등록완료 <span class="profile-num">{{ aprvProfiles.length }}</span>건</div>
          <div class="artist-list-wrap" :class="{'active': expandEnd == true}">
            <a-list :data="aprvProfiles" v-if="aprvProfiles" noDivisionLine :profilePage="{profile: true, state: 'done'}" @delete-profile="deleteProfile"/>
            <div class="btn-expand-wrap">
              <button v-if="aprvProfiles.length > 5" type="button" class="btn-expand-list" @click="expandListEnd()"></button>
            </div>
          </div>
        </div>
        <div class="my-profile-list" v-if="notAprvProfiles && notAprvProfiles.length > 0">
          <div class="profile-sec-tit">등록대기 <span class="profile-num">{{ notAprvProfiles.length }}</span>건</div>
          <div class="artist-list-wrap" :class="{'active': expandIng == true}">
            <a-list :data="notAprvProfiles.filter(v=> v.artistProfileStep != 2)" v-if="notAprvProfiles.filter(v=> v.artistProfileStep != 2)" noDivisionLine :profilePage="{profile: true, state: 'temp'}" @delete-profile="deleteProfile"/> <!-- 임시 저장 -->
            <a-list :data="notAprvProfiles.filter(v=> v.artistProfileStep == 2)" v-if="notAprvProfiles.filter(v=> v.artistProfileStep == 2)" noDivisionLine :profilePage="{profile: true, state: 'wait'}" @delete-profile="deleteProfile"/> <!-- 승인 대기 -->
            <div class="btn-expand-wrap">
              <button v-if="notAprvProfiles.length > 5" type="button" class="btn-expand-list" @click="expandListIng()"></button>
            </div>
          </div>
        </div>
      </div>
      <!-- // CASE : profile 있음 -->
      <footer-box
        submitText="프로필 추가"
        :submit-cb="() => nextStep(true)"        
        :style="{position: 'sticky', bottom: 0, width: 'calc(100% + 6rem', margin: 'auto -3rem 0', zIndex: 'initial'}" 
      >
        <icon
          slot="submitLeftSlot"
          src="img/common/plus-white.png"
          width="3.2rem"
          height="3.2rem"
          :cover="false"
          :style="{
            display: 'inline-block',
            marginBottom: '-8px',
          }"
        />
      </footer-box>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'
import LabelButton from '@/components/common/LabelButton'
import AList from '@/components/artist/AList'
import AEvaluation from '@/components/artist/AEvaluation.vue'
import FooterBox from '@/components/common/FooterBox.vue'
import Icon from '@/components/common/Icon'
import { ref } from '@vue/composition-api'

export default {
  name: 'ProfileList',
  components: {
    PageHeader,
    LabelButton,
    AList,
    FooterBox,
    Icon,
    AEvaluation,
  },
  data() {
    const userData = this.$store.state.user.userData
    const message = true;
    const expandEnd = ref(false);
    const expandIng = ref(false); 
    var hasNewAlarm = false;
    return {
      scroll: {
        lastY: 0,
      },
      history: {
        exit: false,
      },
      message,
      hasNewAlarm,
      expandEnd,
      expandIng,
    }
  },
  computed: {
    showProfileSelectModal() {
      return this.$store.getters.isShowProfileSelectModal
    },
    showProfileSelectFullModal() {
      return this.$store.getters.isShowProfileSelectFullModal
    },
    userData: {
      get() {
        return this.$store.state.user.userData
      },
    },
    notAprvProfiles: {
      get() {
        return this.profiles.filter(v => v.artistProfileStep != 3 && v.categoryUid == 1)
      },
    },
    aprvProfiles: {
      get() {
        return this.profiles.filter(v => v.artistProfileStep == 3 )
      },
    },
    mainProfile: {
      get() {
        return this.profiles.find(v => v.isPickPro == true ) || this.profiles[0]
      },
    },
    profiles(){
      return this.$store.getters.getArtistProfiles;
    },
    showNoProfile() {
      return this.profiles.length < 1;
    }
  },
  created() {
    this.getArtistProfiles()
    this.getHasNewAlarm();
    this.$eventBus.$on('refresh', route => {
      if (route.path === '/profile') {
        this.$nextTick()
          .then(() => {
            this.$refs?.container?.scrollTo?.({ top: 0 })
          })
      }
    })
  },
  beforeRouteEnter(to, from, next) {
    // 컴포넌트가 렌더링되기 전에 호출되므로 여기서 프로필 목록을 가져옴
    next(Vue => {
      Vue.getArtistProfiles();
    });
  },
  beforeRouteLeave(to, from, next) {
    setTimeout(() => {
      if (this.$store.state.history.back) {
        this.$runToApp({
          androidCb: () => {
            if (!this.history.exit) {
              this.$toast('뒤로 버튼을 한번 더 누르시면 종료됩니다.')
              this.history.exit = true
              setTimeout(() => (this.history.exit = false), 2000)
            } else {
              location.href = 'hbscheme://appExit'
            }
            next(false)
          },
          iosCb: () => next(false),
          webCb: () => next(false),
        })
      } else {
        this.scroll.lastY = this.$refs.container.scrollTop
        next()
      }
    })
  },
  methods: {
    getArtistProfiles() {
      this.$store.dispatch('getProfileList')
    },
    getHasNewAlarm(){
      if (!this.userData.useridx) return Promise.resolve()
      
      const req = {
          method: 'get',
          url: `/artist/hasAlarm`,
      }
      return this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)
          const resultData = this.$lib.resultCheck(data.resultData)
          
          if (resultData.success) {
            let artistData = new Array();
            let sum = 0;
            var alarms = data.response.profileAlarms;
            if(alarms){
              alarms.forEach((v, index) => {
                if(index > 0){
                  sum = sum + parseInt(v.hasNewDirectOffer) + parseInt(v.hasNewMsg) + parseInt(v.hasNewContact);
                }
              });
              if(sum > 0) this.hasNewAlarm = true;
            }
          } else {
            this.$alert(resultData)
          }
          return
          })
        .catch((e) => {
          log.error(e)
          return
        })
    },
    nextStep(isNew=false) {
      if(isNew){
        this.$store.commit('setArtistData', null)
        this.$store.commit('setArtistProfileStep', 1)
      }
      this.$router.push(`/artist/my-profile`)
    },
    openBottomSheet() {
      if(this.aprvProfiles.length > 0 && !this.showProfileSelectModal) {
        this.$store.commit('setTooltipShow', false);
        this.$store.commit('setProfileSelectModal', !this.showProfileSelectModal);
      }
    },
    deleteProfile(val){
      if(val){
        this.getArtistProfiles()
      }
    },
    modalClose() {
      this.$store.commit('setProfileSelectModal', !this.showProfileSelectModal);
      this.getHasNewAlarm()
    },
    expandListEnd() {
      if(this.expandEnd == true) {
        this.expandEnd = false;
      } else {
        this.expandEnd = true;
      }
    },
    expandListIng() {
      if(this.expandIng == true) {
        this.expandIng = false;
      } else {
        this.expandIng = true;
      }
    }
  },
}
</script>

<style lang="scss">
</style>


<style lang="scss" scoped>
@import '@/assets/css/constant.scss';

#page > .container {
  padding-bottom: 0;
  .page-header {
    padding-bottom: 1.6rem;
  }
  .guideLetter {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 6rem;
    padding: 1.2rem 2rem;
    border-radius: 0.8rem;
    background-color: rgba(#AB54BB,0.1);
    font-size: 1.6rem;
    line-height: 2.1rem;
    color: #AB54BB;
  }

  .profile-sec-tit {
    color: black;
    font-size: 2.5rem;
    font-weight: 600;
    span {
      color: $color-deepLavender;
      &.line-badge {
        padding: 0.3rem 1.2rem;
        border: 1px solid $color-deepLavender;
        border-radius: 2.8rem;
        font-size: 1.6rem;
        vertical-align: middle;
      }
    }
  }

  .profile-registration-step-wrap {
    margin: 2.4rem 0 6rem;
    padding: 4rem 3rem;
    border-radius: 0.6rem;
    background-color: #F7F8FE;
    .profile-registration-step {
      padding-left: 0;
      padding-bottom: 3rem;
      border-bottom: 1px solid #D9D9D9;
      li {
        display: flex;
        align-items: center;
        gap: 2.4rem;
        img {
          width: 10rem;
          height: 10rem;
        }
        .step-tit {
          font-size: 2.4rem;
          color: #333;
          font-weight: 600;
          line-height: 3.4rem;
        }
        .step-txt {
          margin-top: 0.2rem;
          color: #666;
          font-size: 2rem;
          letter-spacing: -0.05rem;
        }
        .guide-btn {
          margin-top: 1.4rem;
          font-size: 2rem;
          line-height: 2.8rem;
        }
        & ~ li {
          position: relative;
          margin-top: 3.8rem;

          &::after {
            position: absolute;
            top: -2.4rem;
            left: 4.1rem;
            width: 1.6rem;
            height: 1.2rem;
            background: url(/img/my-profile/profile-arrow@2x.png) no-repeat 0 0/ contain;
            content: '';
          }
        }
      }
    }
    .notice-area {
      margin-top: 3rem;
      font-size: 2rem;
      .notice-tit {
        margin-bottom: 1.4rem;
        color: #333;
      }
      .notice-list {
        li {
          margin-left: 2rem;
          color: #666;
          list-style: disc;
          & ~ li {
            margin-top: 0.8rem;
          }
        }
      }
    }
  }

  .tip-list {
    margin-top: 2.4rem;
    padding: 3rem;
    border-radius: 1.2rem;
    background-color: #f5f5f5;
    li {
      margin-left: 2rem;
      font-size: 2rem;
      color: #666;
      list-style: disc;
      & ~ li {
        margin-top: 0.8rem;
      }
    }
  }

  .current-profile-area {
    position: relative;
    margin: 3rem 0 6rem;
    .current-profile {
      position: relative;
      display: flex;
      align-items: center;
      padding: 2rem 2rem 2rem 3rem;
      border: 1px solid $color-deepLavender;
      border-radius: 1.2rem;
      // box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.16);
      background-color: white;
      // z-index: 2;

      .artist-img {
        flex-shrink: 0;
        width: 9.2rem;
        height: 9.2rem;
        border-radius: 8rem;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .artist-info {
        max-width: calc(100% - 10.8rem);
        margin-left: 1.6rem;
        .profile-top {
          display: flex;
          align-items: center;
          margin-bottom: 0.8rem;

          .artist-name {
            width: 100%;
            font-size: 2.4rem;
            font-weight: 600;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .artist-category {
          color: #666666;
          font-size: 2rem;
        }
        .new {
          flex-shrink: 0;
          width: 1rem;
          height: 1rem;
          margin-left: 0.6rem;
          margin-right: 0.4rem;
          border-radius: 50%;
          background-color: #F33D12;
        }
      }
    }
  }

  .my-profile-list {
    // + .my-profile-list {
    //   margin-top: 4rem;
    // }
    .artist-list-wrap {
      overflow: hidden;
      position: relative;
      max-height: 125rem;
      margin-bottom: 4rem;
      .artist-list {
        margin-top: -0.4rem;
        margin-bottom: 6rem;
        &:has(+.artist-list) {
          margin-bottom: 0;
        }
        +.artist-list {
          opacity: 0;
        }
      }
      .btn-expand-wrap {
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        width: 100%;
        height: 2.8rem;
        margin-top: 2.8rem;
        background-color: #fff;
        .btn-expand-list {
          display: block;
          width: 3.2rem;
          height: 3.2rem;
          margin: 0 auto;
          background: url(/img/my-profile/icon_arrow@3x.png) no-repeat 50% 50% / 2.8rem 2.8rem;
        }
      }
      &.active {
        max-height: none;
        .btn-expand-list { 
          transform: rotate(180deg);
        }
        .artist-list {
          opacity: 1;
        }
      }
    }
  }
}
</style>
